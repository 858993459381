<template>
  <div class="blog-main">
    <div class="blog-wrapper mt-2 mb-4 shadow-sm">
      <!-- LOADING SKELETON -->
      <div v-if="isFetchingPost || !post" class="blog-cover-box">
        <b-skeleton animation="wave" height="100%" width="100%"></b-skeleton>
      </div>
      <div v-if="isFetchingPost || !post" class="blog-data">
        <div class="d-flex align-items-center text-center flex-column">
          <b-skeleton animation="wave" height="20px" width="70%"></b-skeleton>
          <b-skeleton animation="wave" height="20px" width="100px"></b-skeleton>
          <div class="d-flex my-1" style="gap: 8px; flex-wrap: wrap">
            <b-skeleton
              animation="wave"
              height="20px"
              width="60px"
            ></b-skeleton>
            <b-skeleton
              animation="wave"
              height="20px"
              width="60px"
            ></b-skeleton>
            <b-skeleton
              animation="wave"
              height="20px"
              width="60px"
            ></b-skeleton>
          </div>
          <div class="w-100">
            <b-skeleton
              class="mb-1"
              animation="wave"
              height="20px"
              width="70%"
            ></b-skeleton>
            <b-skeleton
              class="mb-1"
              animation="wave"
              height="20px"
              width="50%"
            ></b-skeleton>
            <b-skeleton
              class="mb-1"
              animation="wave"
              height="20px"
              width="60%"
            ></b-skeleton>
          </div>
        </div>
        <hr />

        <div>
          <b-skeleton animation="wave" height="20px" width="100%"></b-skeleton>
          <b-skeleton animation="wave" height="20px" width="100%"></b-skeleton>
          <b-skeleton animation="wave" height="20px" width="100%"></b-skeleton>
          <b-skeleton animation="wave" height="20px" width="100%"></b-skeleton>
          <b-skeleton animation="wave" height="20px" width="100%"></b-skeleton>
          <b-skeleton animation="wave" height="20px" width="100%"></b-skeleton>
        </div>
      </div>

      <div v-if="!isFetchingPost && post" class="blog-cover-box">
        <img
          :src="
            post.cover || require('@/assets/images/tourismeBenin/musee.jpg')
          "
          alt="musee"
          class="blog-cover-img"
        />
      </div>
      <div v-if="!isFetchingPost && post" class="blog-data">
        <div class="d-flex align-items-center text-center flex-column">
          <h1>{{ post.title }}</h1>
          <small>
            {{ post.created_at }}
            <!-- {{
                  replaceSlashByDash(convertDateToLocalString(post.created_at))
                }} -->
          </small>
          <div class="d-flex my-1" style="gap: 8px; flex-wrap: wrap">
            <b-badge
              variant="primary"
              v-for="(tag, index) in post.tags"
              :key="index"
              >#{{ tag }}</b-badge
            >
          </div>
          <p>
            {{ post.short_description }}
          </p>
        </div>

        <hr />

        <div class="post-html">
          <div id="content">
            <p v-html="post.description"></p>
          </div>
        </div>
        <hr />
        <div>
          <h4>Fichier(s) attaché(s)</h4>
          <DownloadFile :documents="post.attached_files" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { BBadge, BSkeleton } from 'bootstrap-vue';
import { mapActions } from 'vuex';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

import PostsStoreModule from '@/store/blog';
import DownloadFile from '@/components/DownloadFile.vue';

import {
  registerStoreModule,
  unregisterStoreModule,
} from '@/helpers/vuex-utils';

export default {
  components: {
    BBadge,
    BSkeleton,
    DownloadFile,
  },
  setup() {
    const requiredStoreModules = [{ path: 'posts', module: PostsStoreModule }];
    // Register module
    registerStoreModule(requiredStoreModules);
    return {
      requiredStoreModules,
    };
  },
  data() {
    return {
      currentPostId: null,
      isFetchingPost: false,
      // post: {
      //   title: null,
      //   post_category_id: null,
      //   description: null,
      //   tags: null,
      //   attached_files: null,
      //   cover: null,
      //   status: null,
      // },
      post: null,
    };
  },
  mounted() {
    this.loadPost();
  },
  methods: {
    ...mapActions('posts', {
      action_getPosts: 'getPosts',
    }),
    loadPost() {
      this.isFetchingPost = true;
      this.action_getPosts(this.$route.params.id)
        .then((response) => {
          this.isFetchingPost = false;
          this.post = response.data.data;
          console.log('POST 🔥: ', this.post);
        })
        .catch((error) => {
          // console.log(error)
          this.isFetchingPost = false;
          // this.errored = true
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Une erreur est survenue lors du chargement de la page',
              icon: 'CoffeeIcon',
              variant: 'danger',
              text: '',
            },
          });
        });
    },
  },
};
</script>

<style scoped>
.blog-main {
  padding: 0 2em;
}
.blog-wrapper {
  max-width: 1000px;
  margin: 0 auto;
  border-radius: 10px;
  overflow: hidden;
  background-color: #fff;
}
.blog-cover-box {
  width: 100%;
  aspect-ratio: 16/6;
  overflow: hidden;
}
.blog-cover-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: 0.5s ease;
}
.blog-cover-img:hover {
  transform: scale(1.05) !important;
}
.blog-data {
  padding: 2em;
}

::v-deep .post-html .img {
  width: 75%;
  margin: 0 auto !important;
}

::v-deep .post-html #content {
  width: 100% !important;
  overflow: hidden;
  margin: 0 auto !important;
}
::v-deep .post-html #content img {
  width: 50% !important;
  background-size: cover !important;
  background-position: center center !important;
  aspect-ratio: 16/9 !important;
  border-radius: 5px !important;
}
::v-deep .post-html #content p img {
  display: flex;
  justify-content: center;
}
</style>
